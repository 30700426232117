import { render, staticRenderFns } from "./variant-size-selection.component.vue?vue&type=template&id=61c7dfac&scoped=true"
import script from "./variant-size-selection.component.vue?vue&type=script&lang=js"
export * from "./variant-size-selection.component.vue?vue&type=script&lang=js"
import style0 from "./variant-size-selection.component.vue?vue&type=style&index=0&id=61c7dfac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c7dfac",
  null
  
)

export default component.exports