<template>
  <div>
    <div>
      <div class="loader-wrapper" v-if="this.isLoading">
        <AppLoader
          :showLoadingText="true"
          loadingText="Loading Variants And Sizes..."
        />
      </div>
      <div v-else class="variant-size-selector">
        <div class="variant-selector" v-if="this.hasVariant">
          <label class="label"
            >Select Variant <span class="required">*</span></label
          >
          <div class="variant-options" v-if="!isLoading">
            <button
              v-for="variant in variants"
              :key="variant.uid"
              :class="{ active: selectedVariant?.uid === variant.uid }"
              @click="selectVariant(variant)"
              class="variant-option"
            >
              {{ variant.name }}
            </button>
          </div>
        </div>

        <div class="size-selector" v-if="this.sizes.length > 0">
          <div class="size-header">
            <label class="label"
              >Select Size <span class="required">*</span></label
            >
            <div class="size-chart">
              <a href="#">Size Chart</a>
            </div>
          </div>
          <div class="size-options">
            <button
              v-for="size in sizes"
              :key="size.value"
              :class="{
                active: selectedSize?.value == size.value,
                disabled: size.isOutOfStockForExchange || !size.can_exchange,
              }"
              @click="selectSize(size)"
              class="size-option"
            >
              {{ size.value }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="continue-button">
      <Button
        :button-text="'Continue'"
        :show-arrow-icon="true"
        :button-class-type="'primary'"
        :aria-label="'continue-button'"
        :aria-described-by="'select-variant-size-cta'"
        :is-disabled="getIsContinueDisabled"
        @button-click="handleContinueClick"
      />
    </div>
  </div>
</template>

<script>
import Button from "../../../../common/button/button.component.vue";
import AppLoader from "../../../../common/loader/loader.component.vue";

import {
  getProductPrice,
  getProductVariants,
} from "../../../../services/product.service.js";
import { isUndefinedNullOrEmpty } from "../../../../utils/helpers.js";

export default {
  name: "VariantSizeSelection",
  inject: ["store"],
  components: {
    Button,
    AppLoader,
  },
  data() {
    return {
      hasVariant: false,
      variants: [],
      sizes: [],
      selectedVariant: null,
      selectedSize: null,
      variantResponse: null,
      isLoading: true,
      isFetchingPrice: false,
    };
  },
  async mounted() {
    if (isUndefinedNullOrEmpty(this.store.state.exchangeProduct.getVariants)) {
      await this.getProductVariants();
    } else {
      this.variantResponse = this.store.state.exchangeProduct.getVariants;
      this.variants = this.store.state.exchangeProduct.getVariants.variants;
      this.hasVariant =
        this.store.state.exchangeProduct.getVariants.has_variants;
      if (!this.store.state.exchangeProduct.getVariants.has_variants) {
        this.selectedVariant =
          this.store.state.exchangeProduct.getVariants.variants[0];
      }
    }

    this.isLoading = false;

    if (
      !isUndefinedNullOrEmpty(
        this.store.state.exchangeProduct.newExchangeProductSize
      )
    ) {
      this.selectedSize =
        this.store.state.exchangeProduct.newExchangeProductSize;
    }

    if (
      !isUndefinedNullOrEmpty(
        this.store.state.exchangeProduct.newExchangeProductVariant
      )
    ) {
      const selectedVariantDetails = this.variants.find(
        (variant) =>
          this.store.state.exchangeProduct.newExchangeProductVariant.uid ===
          variant.uid
      );

      this.selectedVariant = selectedVariantDetails;
    }
  },
  computed: {
    getIsContinueDisabled() {
      if (this.isFetchingPrice) {
        return true;
      }
      if (this.hasVariant)
        // If Variant Exists
        return this.selectedVariant == null || this.selectedSize == null;
      return this.selectedSize == null;
    },
  },
  watch: {
    selectedVariant(variant) {
      this.selectedSize = null;
      const selectedVariantDetails = this.variantResponse.variants.find(
        (variantDetails) => variantDetails.uid === variant.uid
      );

      this.sizes = selectedVariantDetails.sizeDetails.sizes.map(
        (sizeDetails) => {
          return sizeDetails;
        }
      );
    },
    async selectedSize(newSelectedSize) {
      if (newSelectedSize) {
        const priceAndStoreDetails = await this.getPriceAndStoreDetails(
          newSelectedSize
        );
        console.log(priceAndStoreDetails);

        const detailedObj = {
          selectedVariant: this.selectedVariant,
          selectedSize: this.selectedSize,
        };
        this.$emit("selectExchangeProduct", detailedObj);
      } else {
        this.$emit("selectExchangeProduct", null);
      }
    },
  },
  methods: {
    async getProductVariants() {
      try {
        const returnProductDetails = {
          item_slug: this.store.state.exchangeProduct.selectedProduct.slug_key, // Item SLUG
          item_size: this.store.state.exchangeProduct.selectedProduct.size,
          price: this.store.state.exchangeProduct.selectedProduct.price,
        };

        const { data } = await getProductVariants(returnProductDetails);

        const variants = data.data.variants;
        const hasVariant = data.data.has_variants;

        if (!hasVariant) {
          this.selectedVariant = variants[0];
        }

        this.variants = variants;
        this.hasVariant = hasVariant;

        this.variantResponse = data.data;

        this.store.commit("exchangeProduct/SET_VARIANTS", this.variantResponse);
      } catch (err) {
        console.error("Error in getting variants:", err);
      }
    },
    async getPriceAndStoreDetails(sizeDetails) {
      try {
        this.isFetchingPrice = true;
        const deliveryPincode = this.store.state.addressData
          .isPickupSameAsDelivery
          ? this.store.state.addressData.selectedPickupAddress.area_code
          : this.store.state.addressData.selectedDeliveryAddress.area_code;

        const payload = {
          slug: sizeDetails?.slug,
          size: sizeDetails?.value,
          pincode: deliveryPincode,
        };
        const { data } = await getProductPrice(payload);
        const { seller, store, price, delivery_promise } = data.data;
        const priceAndStoreDetails = {
          seller,
          store,
          price,
          delivery_promise,
        };
        this.store.commit(
          "exchangeProduct/SET_EXCHANGE_PRODUCT_PRICE_AND_STORE",
          priceAndStoreDetails
        );

        this.isFetchingPrice = false;
      } catch (err) {
        console.error("Error in getting Price Details:", err);
      }
    },
    selectVariant(variant) {
      this.selectedVariant = variant;
    },
    selectSize(size) {
      this.selectedSize = size;
    },
    showSizeChart() {
      // TODO: Implement the logic to show the size chart
    },
    handleContinueClick() {
      if (this.selectedVariant) {
        const selectedVariantDetails = this.variantResponse.variants.find(
          (variant) => variant.uid === this.selectedVariant.uid
        );

        this.store.commit(
          "exchangeProduct/SET_EXCHANGE_PRODUCT_VARIANT",
          selectedVariantDetails
        );
      }

      this.store.commit(
        "exchangeProduct/SET_EXCHANGE_PRODUCT_SIZE",
        this.selectedSize
      );

      this.$emit("complete");
    },
  },
};
</script>

<style scoped lang="scss">
.variant-size-selector {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.variant-selector {
  .label {
    color: var(--Text-Label, var(--Text-Placeholder-Text, #747474));
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .required {
    color: #f00;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .variant-options {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .variant-option {
      position: relative;
      display: flex;
      padding: 4px 8px;
      align-items: center;
      cursor: pointer;
      background: #fff;

      border-radius: 4px;
      border: 1px solid #e0e0e0;

      color: var(--Text-Body, #373737);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.24px;
    }

    .variant-option.active {
      border-color: 1px solid var(--buttonPrimary, #4e3f09);
      /* background: rgba(var(--buttonPrimary), 0.8); */
      color: var(--buttonPrimary, #4e3f09);
    }

    /**TODO: Fix the background for the button */
    .variant-option.active::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--buttonPrimary, #4e3f09);
      opacity: 0.8; /* Set the desired opacity */
      z-index: -1; /* Place it behind the button text */
      border-radius: inherit; /* Maintain border radius if any */
    }
  }
}

.size-selector {
  .size-header {
    display: flex;
    justify-content: space-between;

    .label {
      color: var(--Text-Label, var(--Text-Placeholder-Text, #747474));
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }

    .required {
      color: #f00;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }

    .size-chart {
      color: var(--buttonPrimary, #4e3f09);

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }
  }

  .size-options {
    margin-top: 8px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    .size-option {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 32px;
      height: 32px;

      border-radius: 50%;
      border: 1px solid #d0d0d0;
      background: #fff;

      color: #1f1f1f;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }

    .size-option.active {
      border-color: 1px solid var(--buttonPrimary, #4e3f09);
      /* background: rgba(var(--buttonPrimary), 0.8); */
      color: var(--buttonPrimary, #4e3f09);
    }

    .size-option.disabled {
      background: #ebebeb;
      color: #929293;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.continue-button {
  margin-top: 8px;
}
</style>
