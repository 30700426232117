<template>
  <div v-if="cardData" class="card-container">
    <!-- TITLE -->
    <div class="card-title">
      {{ cardTitle }}
    </div>
    <!-- CONTENT -->
    <div class="card-content">
      <div class="product-details-container">
        <div class="product-img-container">
          <img :src="cardData.productImg" alt="product-img" />
        </div>
        <div class="product-content">
          <p class="product-name">{{ cardData.name }}</p>
          <p class="product-desc">{{ cardData.desc }}</p>
          <div class="size-qty-container">
            <span>Size: {{ cardData.size }}</span>
            <span>Qty: {{ cardData.quantity }}</span>
          </div>
          <p class="product-price">Price: {{ cardData.price }}</p>
        </div>
      </div>
      <div></div>
    </div>
    <!-- FOOTER -->
    <div class="card-footer">
      <div class="exchange-date-container">
        <img src="../../assets/exchange-symbol.svg" alt="exchange-symbol" />
        <span>{{ dateText }}</span>
      </div>
      <div class="view-policy">View Policy</div>
    </div>
  </div>
</template>

<script>
// import PRODUCT_TYPE from "../../utils/constants";

export default {
  name: "ProductDetailsCardDesktop",
  props: {
    data: {
      default: null,
    },
  },
  computed: {
    cardData() {
      return this.data;
    },
    dateText() {
      if (this.data.type === "RETURN_PRODUCT") 
        return `Exchange eligible till ${this.data?.exchange_eligibility_date}`;
      return `Delivery expected by ${this.data?.exchange_eligibility_date}`;
    },
    cardTitle() {
      if (this.data.type === "RETURN_PRODUCT") return "Returning Item";
      return "Exchange Product";
    },
  },
};
</script>

<style scoped lang="scss">
.card-container {
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: #f3f3f7;
  margin: 0;

  .card-title {
    color: #747474;
    font-family: unset;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.24px;
    padding: 8px;
    border-bottom: 1px solid #e1e1e1;
  }

  .card-content {
    padding: 8px;

    .product-details-container {
      display: flex;
      gap: 16px;

      .product-img-container {
        width: 64px;
        height: 96px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .product-content {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .product-name {
          color: #1f1f1f;
          font-family: unset;
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: -0.28px;
        }

        .product-desc {
          color: #4e4e4e;
          font-family: unset;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          width: 100%;
        }

        .product-price {
          color: #1f1f1f;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }

        .size-qty-container {
          display: flex;
          gap: 16px;
          align-items: center;

          span:nth-child(1) {
            color: #4e4e4e;
            font-family: unset;
            font-size: 12px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.24px;
          }

          span:nth-child(2) {
            color: #4e4e4e;
            font-family: unset;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.24px;
          }
        }
      }
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    align-items: center;
    gap: 48px;

    .exchange-date-container {
      display: flex;
      gap: 4px;
      align-items: center;

      span {
        color: #747474;
        font-family: unset;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
      }
    }

    .view-policy {
      color: #1b6163;
      font-family: unset;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.24px;
    }
  }
}
</style>
