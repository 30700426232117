<template>
  <div class="loader-container">
    <!-- TODO:Loading Animation -->
    <div class="loader"></div>
    <p v-if="showLoadingText" class="loader-text">{{ loadingText }}</p>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
  props: {
    loadingText: {
      type: String,
      default: "Please Wait Loading Details...",
    },
    showLoadingText:{
      type:Boolean,
      default:false
    }
  },
};
</script>

<style scoped lang="scss">
.loader-container {
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid var(--primaryColor,#023047);
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  .loader-text {
    margin-top: 15px;
    font-family: unset;
    font-size: 15px;
    font-weight: unset;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
