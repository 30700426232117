<template>
  <div class="reason-selection">
    <div v-if="this.isLoading">
      <AppLoader :showLoadingText="true" loadingText="Please Wait Loading Exchange Reasons..." />
    </div>
    <div v-else>
      <div v-if="this.selectedReason === null" class="reason-wrapper">
        <div
          v-for="reason in reasons"
          :key="reason.id"
          class="reason-item"
          @click="selectReason(reason)"
          :class="{ selected: selectedReason?.id === reason.id }"
        >
          {{ reason.display_name }}
        </div>
      </div>
      <div v-if="this.selectedReason !== null">
        <div class="reason-item">
          <span>{{ getSelectedReasonName }}</span>
          <img
            :src="editIcon"
            alt="edit-icon"
            aria-label="edit-selected-reason"
            @click="handleEditReasonClick"
          />
        </div>
        <div class="radio-group">
          <label
            v-for="subReason in getSubReasons"
            :key="subReason.id"
            class="radio-element"
          >
            <input
              type="radio"
              :value="subReason"
              v-model="selectedSubReason"
            />
            <div class="radio-label">{{ subReason.display_name }}</div>
          </label>
        </div>
        <div class="upload-wrapper" v-if="getIsUploadRequired">
          <div>
            <span class="upload-text">Upload Photo</span>
            <span class="star"> *</span>
          </div>
          <div class="upload-description">
            Make sure the product tag is visible in the picture.
          </div>
          <div class="photo-upload-wrapper">
            <div
              v-for="(image, index) in uploadedImages"
              class="uploaded-image-wrapper"
              :key="`image-${index}`"
            >
              <img
                :src="image"
                class="uploaded-image"
                alt="uploaded-image"
                :aria-label="`uploaded-image-${index + 1}`"
              />
              <div class="cross-icon" @click="handleRemoveImageClick(index)">
                <img
                  :src="crossIcon"
                  alt="cross-icon"
                  aria-label="remove-uploaded-image"
                />
              </div>
            </div>
            <div class="photo-upload">
              <div v-if="this.isUploadingMedia">
                <AppLoader/>
              </div>
              <div v-else>
                <img
                  :src="fileUploadIcon"
                  alt="upload file"
                  aria-label="photo-upload"
                />
                <input
                  type="file"
                  @change="this.uploadFile"
                  id="photo-upload"
                  accept="image/*"
                  aria-describedby="upload-instructions"
                  aria-label="Upload an image or video"
                />
              </div>
            </div>
          </div>
          <div id="upload-instructions" class="upload-instructions">
            Accepted image formats jpg & png, File size should be less than 2mb
          </div>
        </div>
      </div>
      <div class="error" v-if="this.uploadError">{{ this.uploadError }}</div>
      <div class="continue-button">
        <Button
          :button-text="'Continue'"
          :show-arrow-icon="true"
          :button-class-type="'primary'"
          :aria-label="'continue-button'"
          :aria-described-by="'select-product-cta'"
          :is-disabled="getIsContinueDisabled"
          @button-click="handleContinueClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../../common/button/button.component.vue";
import AppLoader from "../../../../common/loader/loader.component.vue";

import uploadImage from "../../../../services/image-upload.service.js";
import { getReasons } from "../../../../services/reasons.service.js";
import { isUndefinedNullOrEmpty } from "../../../../utils/helpers.js";

import EditIcon from "../../../../assets/edit-icon.svg";
import CrossIcon from "../../../../assets/cross-icon.svg";
import FileUploadIcon from "../../../../assets/file-upload-icon.svg";

const MAX_FILE_SIZE_IN_MB = 2;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
const MAX_IMAGES_TO_BE_UPLOADED = 5;

export default {
  name: "ReasonSelection",
  inject: ["store"],
  components: {
    Button,
    AppLoader,
  },
  data() {
    return {
      isUploadingMedia: false,
      isLoading: true,
      selectedReason: null,
      selectedSubReason: null,
      editIcon: EditIcon,
      fileUploadIcon: FileUploadIcon,
      crossIcon: CrossIcon,
      uploadedImages: [],
      reasons: [],
      uploadError: null,
    };
  },
  async mounted() {
    // Storing the Reasons in the Vue Store after the first call and using it here
    if (isUndefinedNullOrEmpty(this.store.state.reasonData.getReasonData)) {
      await this.getReasons();
    } else {
      this.reasons = this.store.state.reasonData.getReasonData;
    }
    
    this.isLoading = false;

    if (!isUndefinedNullOrEmpty(this.store.state.reasonData.selectedReason)) {
      this.selectedReason = this.store.state.reasonData.selectedReason;
    }

    if (
      !isUndefinedNullOrEmpty(this.store.state.reasonData.selectedSubReason)
    ) {
      this.selectedSubReason = this.store.state.reasonData.selectedSubReason;
    }

    if (!isUndefinedNullOrEmpty(this.store.state.reasonData.uploadedImages)) {
      this.uploadedImages = this.store.state.reasonData.uploadedImages;
    }
  },
  computed: {
    getSelectedReasonName() {
      const reasonName = this.reasons.find(
        (reason) => reason.id === this.selectedReason?.id
      ).display_name;

      return reasonName;
    },
    getSubReasons() {
      const subReasons =
        this.reasons.find((reason) => reason.id === this.selectedReason?.id)
          ?.reasons ?? [];

      return subReasons;
    },
    getIsUploadRequired() {
      const selectedReason = this.selectedReason;
      const selectedSubReason = this.selectedSubReason;

      const isMediaUploadRequired =
        selectedReason?.qc_type?.includes("pre_qc") ||
        selectedSubReason?.qc_type?.includes("pre_qc");
      return isMediaUploadRequired ?? false;
    },
    getIsContinueDisabled() {
      const subReasons = this.getSubReasons;
      const isSubReasonPresent = subReasons.length !== 0;
      const isSubReasonNotSelected =
        isSubReasonPresent && this.selectedSubReason == null;
      return (
        this.selectedReason == null ||
        isSubReasonNotSelected ||
        (this.getIsUploadRequired && this.uploadedImages.length === 0)
      );
    },
  },
  methods: {
    async getReasons() {
      try {
        this.isLoading = true;
        // Using selected product details from the vue store selected at step one
        const shipmentId = this.store.state.exchangeProduct.shipmentId;
        const bagId = this.store.state.exchangeProduct.selectedProduct.bag_id;
        const lineNumber =
          this.store.state.exchangeProduct.selectedProduct.line_number;

        const reasonResponse = await getReasons({
          shipment_id:shipmentId,
          bag_id:bagId,
          line_number:lineNumber,
        });

        if (reasonResponse) {
          this.reasons = reasonResponse.data.data.reasons;

          this.store.commit(
            "reasonData/SET_REASON_DATA",
            reasonResponse.data.data.reasons
          );
        }
      } catch (err) {
        console.error("Error fetching reasons:", err);
      }
    },
    selectReason(reasonObj) {
      this.selectedReason = reasonObj;
    },
    handleEditReasonClick() {
      this.selectedReason = null;
      this.selectedSubReason = null;
      this.uploadedImages = [];
    },
    handleRemoveImageClick(index) {
      this.uploadedImages.splice(index, 1);
    },
    async uploadFile(event) {
      try {
        this.uploadError = null;
        this.isUploadingMedia = true;
        const file = event.target.files[0];

        if (this.uploadedImages.length === MAX_IMAGES_TO_BE_UPLOADED) {
          this.uploadError = `Maximum upto ${MAX_IMAGES_TO_BE_UPLOADED} images is allowed`;
          return;
        }

        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          this.uploadError = "Maximum file size upto 2mb is supported";
          return;
        }

        if (file.type.startsWith("image/")) {
          const imageUploadRes = await uploadImage(file);

          if (imageUploadRes) {
            this.uploadedImages.push(imageUploadRes?.data?.cdn?.url);
            this.uploadError = null;
          }
        } else {
          this.uploadError = "Only Image format is supported";
        }
      } catch (err) {
        console.error(err);
        this.uploadError = "Error in uploading image";
      }finally{
        this.isUploadingMedia = false;
      }
    },

    handleContinueClick() {
      let selectedReasonText = this.selectedReason?.display_name;

      if(this.selectedSubReason){ // If subReason Exist -> Overwrite with it
        selectedReasonText = this.selectedSubReason?.display_name;
      }

      this.store.commit("reasonData/SET_SELECTED_REASON_DATA", {
        selectedReason: this.selectedReason,
        selectedSubReason: this.selectedSubReason ?? null,
        uploadedImages: this.uploadedImages,
        selectedReasonText,
      });

      this.$emit("complete");
    },
  },
};
</script>

<style scoped lang="scss">
.reason-selection {
  margin-top: 8px;

  .reason-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .reason-item {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;

    background: #fff;
    color: var(--Text-Body, #373737);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.24px;
    cursor: pointer;
  }

  .continue-button {
    margin-top: 8px;

    .button {
      display: inline-flex;
      align-items: center;
    }
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .radio-element {
    display: flex;
    align-items: center;
    cursor: pointer;

    gap: 8px;
  }

  .radio-element input {
    margin: 0;
    accent-color: var(--buttonPrimary, #000);
  }

  .radio-label {
    color: var(--Text-Body, #373737);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: -0.24px;
  }
  .upload-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    gap: 8px;

    .upload-text {
      color: var(--Color-Palette-Text-Color-Primary, #1f1f1f);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
    }

    .star {
      color: #f00;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.28px;
    }

    .upload-description {
      color: var(--Text-Body, #373737);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      letter-spacing: -0.24px;
    }

    .upload-instructions {
      color: #a0a0a0;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      letter-spacing: -0.24px;
      margin-top: 4px;
    }

    .photo-upload-wrapper {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      .uploaded-image-wrapper {
        position: relative;
        border-radius: 4px;
        width: 63px;
        height: 63px;

        .uploaded-image {
          object-fit: cover;
          width: 63px;
          height: 63px;
          border-radius: 4px;
        }

        .cross-icon {
          position: absolute;
          right: -6px;
          top: -6px;

          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          padding: 4px;
          border-radius: 50%;
          border: 1px solid #e1e1e1;
          background: #fff;
        }
      }

      .photo-upload {
        cursor: pointer;
        padding: 15.09px;
        border-radius: 4px;
        border: 1px dashed #929293;
        max-width: fit-content;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        input[type="file"] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.error {
  color: #f00;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.28px;
}
</style>
