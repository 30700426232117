<template>
  <div class="payment-link-container">
    <iframe
        class="iframe-container"
        v-if="iframeUrl"
        :src="iframeUrl"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "PaymentLinkFrame",
  inject: ["store"],
  data() {
    return {
      iframeUrl:null
    };
  },
  mounted() {
    if(this.store.state.paymentLink.paymentLinkUrl)
      this.iframeUrl = this.store.state.paymentLink.paymentLinkUrl;
  },
};
</script>

<style scoped lang="scss">

.payment-link-container{  
  width: 300px;
  height: 600px;

  @media screen and (max-width: 768px) {
    width:100vw;
    height: 100vh;
  }

  .iframe-container{
    width: 100%;
    height: 100%;
  }
}
</style>
