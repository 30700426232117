<template>
  <div class="tag-container">
    <div class="left-panel">
      <div class="circle-progress"></div>
      <div v-if="verticalLine" class="vertical-line"></div>
    </div>
    <div class="right-panel">
      <div class="title">{{ cardTitle }}</div>
      <div class="content-container">
        <div class="content-text">
            {{ cardTextValue }}
        </div>
        <div class="img-container">
          <img class="logo" :src="cardLogo" alt="pickup_image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PickupLogo from '../../assets/pickup-logo.svg';
import ExchangeLogo from '../../assets/exchange-logo.svg';
import { EXCHANGE_STATUS_CARD_TYPES } from "../../utils/constants";

export default {
  name: "ExchangeStatusCard",
  props: {
    type:{
        type:String,
        default:EXCHANGE_STATUS_CARD_TYPES.PICKUP
    },
    cardText:{
        type:String,
        default:"",
    },
    showVerticalLine:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
        pickupLogo:PickupLogo,
        exchangeLogo:ExchangeLogo,
    };
  },
  computed:{
    cardLogo(){
        switch(this.type){
            case EXCHANGE_STATUS_CARD_TYPES.PICKUP :  return this.pickupLogo;
            case EXCHANGE_STATUS_CARD_TYPES.EXCHANGE: return this.exchangeLogo;
            default:  return this.pickupLogo
        }
    },
    cardTitle(){
        switch(this.type){
            case EXCHANGE_STATUS_CARD_TYPES.PICKUP :  return "Pickup";
            case EXCHANGE_STATUS_CARD_TYPES.EXCHANGE: return "Exchange";
            default: return "Pickup";
        }
    },
    cardTextValue(){
        return this.cardText;
    },
    verticalLine(){
      return this.showVerticalLine;
    }
  }
};
</script>

<style scoped lang="scss">
.tag-container {
  display: flex;
  gap: 10px;

  .left-panel {
    display: flex;
    flex-direction: column;

    .circle-progress {
      border-radius: 50%;
      border: 1px solid #0b9020;
      width: 12px;
      height: 12px;
    }

    .vertical-line {
      width: 1px;
      background: #e1e1e1;
      height: 100%;
      align-self: center;
    }
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
    
    .title {
      color: #121212;
      font-family: unset;
      font-size: 12px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.24px;
    }

    .content-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      margin-bottom: 10px;

      .content-text {
        color: #373737;
        font-family: unset;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.2px;

        @media screen and (max-width: 768px) {
          font-size: 10px;
        }
      }

      .img-container {
        width: 40px;
        height: auto;

        .logo {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
