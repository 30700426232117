<template>
  <div class="container">
    <div class="inner-container">
      <!-- Top Header -->
      <div class="top-header">
        <div class="success-logo-container">
          <img class="success-img" :src="successAnimation" alt="success_img" />
        </div>
        <p class="title">Exchange Success</p>
      </div>
      <!-- Product content -->
      <div class="product-details">
        <div class="product-img-container">
          <img
            class="product-img"
            :src="newProductImage"
            alt="product-img"
          />
        </div>
        <div class="product-desc">
          For a smooth exchange, please ensure the product is in good condition
          with all tags intact.
          <!-- Estimated delivery date for your new product: -->
          Estimated delivery date for your new product:{{ newProductDeliveryDate }}
        </div>
      </div>
      <!-- Pointer -->
      <div class="points-container">
        <li
          v-for="(item, index) in pointerItems"
          class="pointer-container"
          :key="`point-${index}`"
        >
          <img :src="item.logo" alt="success_icon" />
          <span class="pointer-text" :class="{ active: index === 0 }">{{
            item.text
          }}</span>
        </li>
      </div>
      <!-- Goto Homepage Button -->
      <div class="btn-container">
        <Button
          :button-text="'Go to Homepage'"
          :button-class-type="'primary'"
          :aria-label="'homepage-button'"
          :aria-described-by="'homepage-button'"
          @button-click="handleHomePageClick"
        >
          Go to Homepage
        </Button>
        <Button
          :button-text="'Go to My Orders'"
          :button-class-type="'primary'"
          :aria-label="'my-orders-button'"
          :aria-described-by="'exchange-confirmation-button'"
          @button-click="handleMyOrdersClick"
        >
          Go to My Orders
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../../common/button/button.component.vue";

import { STOREFRONT_ROUTE_PATHS } from "@/utils/constants";

import successColorIcon from "../../../../assets/success_color_icon.svg";
import inventoryIcon from "../../../../assets/inventory_icon.svg";
import shippingIcon from "../../../../assets/shipping_icon.svg";
import SuccessAnimation from "../../../../assets/success_tick.gif";

export default {
  name: "ExchangeConfirmation",
  components: {
    Button,
  },
  inject: ["store"],
  data() {
    return {
      pointerItems: [
        {
          logo: successColorIcon,
          text: "Exchange Request Submitted",
        },
        {
          logo: inventoryIcon,
          text: "Repack the item with original packaging and accompanying return bag.",
        },
        {
          logo: shippingIcon,
          text: "Hand over the package to the delivery agent.",
        },
        {
          logo: shippingIcon,
          text: "Collect the new package from the delivery agent.",
        },
      ],
      successAnimation: SuccessAnimation,
    };
  },
  methods: {
    handleHomePageClick() {
      window.location.href = STOREFRONT_ROUTE_PATHS.HOME_PAGE;
    },
    handleMyOrdersClick() {
      window.location.href = STOREFRONT_ROUTE_PATHS.MY_ORDERS_PAGE;
    },
  },
  computed: {
    newProductImage() {
      const mediaBaseUrl =
          "https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyndnp/wrkr"; //TODO: Need to Replace
      return (
          mediaBaseUrl + this.store.state.exchangeProduct.newExchangeProductVariant?.medias[0]?.url ?? ""
      );
    },
    newProductDeliveryDate(){
      return "Aug 18"; // TODO: To be Get from delivery promise
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  background: white;

  @media screen and (max-width: 768px) {
    margin: 50px 16px 0 16px;
    border-radius: 12px;
  }

  .inner-container {
    margin: 0 auto;
    padding: 24px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .top-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .success-logo-container {
        width: 56px;
        height: 56px;
        margin-bottom: 24px;

        .success-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .title {
        color: #373737;
        text-align: center;
        font-family: unset;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin: 0 0 4px 0;
      }

      .desc {
        color: #747474;
        text-align: center;
        font-family: unset;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.24px;
        margin: 0 40px;
      }
    }

    .product-details {
      background: #f3f3f7;
      border-radius: 4px;
      padding: 12px;
      display: flex;
      gap: 16px;
      @media screen and (max-width: 768px) {
        padding: 8px;
      }

      .product-img-container {
        flex: 1;
        border-radius: 4px;

        .product-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .product-desc {
        flex: 6;
        display: flex;
        align-items: center;
        color: #6d6d6e;
        font-family: unset;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        @media screen and (max-width: 768px) {
          flex: 3;
          font-size: 10px;
          align-items: center;
        }
      }
    }

    .points-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .pointer-container {
        display: flex;
        align-items: center;
        gap: 12px;

        .pointer-text {
          flex: 1;
          color: #1f1f1f;
          font-family: unset;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.24px;
        }

        .active {
          color: #0d5e25;
        }
      }
    }

    .btn-container {
      display: flex;
      gap: 10px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .button {
        border-radius: 4px;
        background: #1b6163;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        font-family: unset;
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
        height: 34px;
        padding: 8px 24px;
        width: 100%;
        border: none;
      }
    }
  }
}
</style>
