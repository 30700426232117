<template>
  <Modal
    :heading="'Exchange With Product'"
    :aria-label="'exchange-modal'"
    v-show="isModalVisible"
    :show-header="showModalHeader"
    @close="closeModal"
  >
    <template v-slot:modal-body>
      <transition name="slide" mode="out-in">
        <component
          :is="currentComponent"
          :key="currentComponent"
          @change-component="handleChangeComponent"
        ></component>
      </transition>
    </template>
  </Modal>
</template>

<script>
import Modal from "../../common/modal/modal.component.vue";
import { default as StepOne } from "../../common/product-selection/product-selection.component.vue";
import { default as StepTwo } from "./components/multi-step-form/multi-step-form.component.vue";
import { default as StepThree } from "./components/exchange-confirmation/exchange-confirmation.component.vue";
import { default as StepFour } from "./components/payment-link-frame/payment-link-frame.component.vue";
import AddAddress from "./components/add-address/add-address.component.vue";

export default {
  name: "ExchangeModal",
  inject: ["store"],
  components: {
    Modal,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    AddAddress,
  },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentComponent: StepOne,
      showModalHeader:true,
    };
  },
  methods: {
    handleChangeComponent(componentName) {
      this.currentComponent = componentName;
      if(this.currentComponent === "StepFour")
        this.showModalHeader = false;
    },
  },
};
</script>

<style scoped lang="scss">
.slide-enter-active {
  transition: all 0.2s ease;
}
.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}
.slide-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-leave-active {
  transition: all 0.2s ease;
}
.slide-leave {
  transform: translateX(0);
  opacity: 1;
}
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
