<template>
  <div class="product-selection">
    <div class="loader-wrapper" v-if="this.isLoading">
      <AppLoader :showLoadingText="true" loadingText="Loading Shipment Details..." />
    </div>
    <div v-else class="product-selection-body">
      <div class="radio-group">
        <label
          v-for="product in products"
          :key="product.bag_id"
          class="radio-element"
        >
          <input
            type="radio"
            :value="product.bag_id"
            v-model="selectedProductId"
            :disabled="!product?.exchangeWindowDetails?.canExchange"
          />
          <div
            class="radio-label"
            :class="{
              'disabled-radio-label':
                !product?.exchangeWindowDetails?.canExchange,
            }"
            :title="
              !product?.exchangeWindowDetails?.canExchange
                ? 'This product cannot be exchanged'
                : ''
            "
          >
            <img
              alt="product-image"
              :src="product.images[0]"
              class="product-image"
            />
            <div class="product-label">
              <span class="product-name">{{ product.name }}</span>
              <div class="product-description">
                <span
                  >Brand: <em class="bold">{{ product.brand }}</em></span
                >
                |
                <span
                  >Size: <em class="bold">{{ product.size }}</em></span
                >
              </div>
            </div>
          </div>
        </label>
      </div>
      <div class="continue-button">
        <Button
          :button-text="'Continue'"
          :show-arrow-icon="true"
          :button-class-type="'primary'"
          :aria-label="'continue-button'"
          :aria-described-by="'select-product-cta'"
          :is-disabled="isContinueDisabled"
          @button-click="handleContinueClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../button/button.component.vue";
import AppLoader from "../loader/loader.component.vue";
import { getExchangeStatus } from "../../services/exchange.service.js";

export default {
  name: "ProductSelection",
  inject: ["store"],
  components: {
    Button,
    AppLoader,
  },
  mounted() {
    //TODO : Add Loader in this screen
    this.getShipmentDetails();
  },
  data() {
    return {
      products: null,
      selectedProductId: null,
      isContinueDisabled: true,
      isLoading: true,
    };
  },
  watch: {
    selectedProductId(newValue) {
      if (newValue) {
        this.isContinueDisabled = false;
      }
    },
  },
  methods: {
    handleContinueClick() {
      const selectedProduct = this.products.find(
        (data) => data.bag_id === this.selectedProductId
      );

      this.store.commit(
        "exchangeProduct/SET_SELECTED_PRODUCT",
        selectedProduct
      );

      this.$emit("change-component", "StepTwo");
    },
    async getShipmentDetails() {
      try {
        this.isLoading = true;
        const shipmentId = this.store.state.exchangeProduct.shipmentId ?? "";

        const { data } = await getExchangeStatus(shipmentId);
        this.products = data.data;
        this.store.commit("exchangeProduct/SET_ALL_PRODUCTS", this.products);
      } catch (err) {
        console.error("Error fetching shipment details:", err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product-selection {
  padding: 24px;
  min-width: 30%;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    height: 100%;
  }

  .loader-wrapper{
    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .product-selection-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .radio-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .radio-element {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;
    }

    .radio-element input {
      margin: 0;
      accent-color: var(--buttonPrimary, #000);
    }

    .radio-label {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }

    .disabled-radio-label {
      opacity: 0.5; /* Makes the label look faded */
    }
    .product-image {
      height: auto;
      width: 50px;
    }

    .product-label {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .product-name {
      color: var(--text-color-primary-and-secondary, #41434c);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    .product-description {
      color: var(--text-color-primary-and-secondary, #41434c);
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 130%;

      .bold {
        font-weight: 500;
      }
    }

    .continue-button {
      margin-top: 20px;
    }
  }
}
</style>
