<template>
  <div class="modal-backdrop">
    <div
      class="modal"
      role="dialog"
      :aria-label="ariaLabel"
      :class="modalClass"
    >
      <header v-if="showHeader" class="modal-header">
        <h2 class="title">{{ heading }}</h2>
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="close-button"
          :aria-describedby="`${ariaLabel}-close-modal-button`"
        >
          <img
            class="close-btn-img"
            alt="close-icon"
            :src="closeIcon"
            aria-label="modal-close-icon"
          />
        </button>
      </header>

      <section class="modal-body">
        <slot name="modal-body"></slot>
      </section>
    </div>
  </div>
</template>

<script>
import CloseIcon from "../../assets/close-icon.svg";
import { MODAL_TYPE } from "../../utils/constants";

export default {
  name: "CommonModal",
  props: {
    ariaLabel: {
      type: String,
      default: "exchange",
    },
    heading: {
      type: String,
      default: "Modal Header",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    modalType: {
      type: String,
      default: MODAL_TYPE.EXCHANGE_MODAL,
    },
  },
  computed: {
    modalClass() {
      return this.modalType === MODAL_TYPE.CANCEL_MODAL ? "cancel-modal" : "";
    },
  },
  data: function () {
    return {
      closeIcon: CloseIcon,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    overflow: auto;
    // min-width: 30vw;

    background: var(--backgrounds-white, #fff);
    z-index: 10;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
      justify-content: unset;
      border-radius: 0px;
    }

    .modal-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e3e3e3;
      align-items: center;
      width: -webkit-fill-available;
      align-items: center;
      padding: 16px 32px;

      color: var(--text-color-primary-and-secondary, #41434c);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;

      @media screen and (max-width: 768px) {
        padding: 16px 12px;
      }

      .title {
        color: #121212;
        font-family: unset;
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    .btn-close {
      border: none;
      cursor: pointer;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      .close-btn-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media screen and (max-width: 768px) {
        padding: 0;
        width: 16px;
        height: 16px;
      }
    }

    .modal-body {
      width: 100%;
      height: 100%;
      background: #f3f3f7;

      @media screen and (max-width: 768px) {
        flex: 1;
        height: auto;
      }
    }
  }

  .cancel-modal {
    min-width: 30vw;
    @media screen and (max-width: 768px) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      border-radius: 12px 12px 0 0;
    }
  }
}
</style>
