const state = {
  selectedProduct: {},
  allProducts: [],
  newExchangeProductVariant: {},
  newExchangeProductSize: {},
  newExchangeProductPriceAndStore:{},
  shipmentId: null,
  getVariants: [],
};

const mutations = {
  SET_SELECTED_PRODUCT(state, payload) {
    state.selectedProduct = payload;
  },
  SET_ALL_PRODUCTS(state, payload) {
    state.allProducts = payload;
  },
  SET_EXCHANGE_PRODUCT_VARIANT(state, payload) {
    state.newExchangeProductVariant = payload;
  },
  SET_EXCHANGE_PRODUCT_SIZE(state, payload) {
    state.newExchangeProductSize = payload;
  },
  SET_EXCHANGE_PRODUCT_PRICE_AND_STORE(state, payload) {
    state.newExchangeProductPriceAndStore = payload;
  },
  SET_SHIPMENT_ID(state, payload) {
    state.shipmentId = payload;
  },
  SET_VARIANTS(state, payload) {
    state.getVariants = payload;
  },
  RESET_STATE(state) {
    // Except ShipmentId Every State needs to be Reset
    state.selectedProduct = {};
    state.allProducts = [];
    state.newExchangeProductVariant = {};
    state.newExchangeProductSize = {};
    state.newExchangeProductPrice= {},
    state.newExchangeProductPriceAndStore= {},
    state.getVariants = [];
  },
};

// In case we need to add actions
// const actions = {
//   setSelectedProduct(context, payload) {
//     context.commit("SET_SELECTED_PRODUCT", payload);
//   },
// };

export default {
  namespaced: true,
  state,
  mutations,
};
